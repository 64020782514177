import {
  FETCH_USER_DATA,
  STORE_USER_DATA,
  UPDATE_USER_ROLES,
  ERROR_ON_FETCH_USER_DATA,
  STORE_ROLES_DATA,
  ERROR_ON_FETCH_ROLES_DATA,
  CREATE_USER_DATA,
  CREATE_USER_COMPLETE,
  TOGGLE_FILTER_PANEL,
  RESET_USER_STATE,
  UPDATE_TABLE_STATE,
} from './constants';

export function fetchUserDataAction(query) {
  return {
    type: FETCH_USER_DATA,
    isLoading: !query?.forceRefresh,
    query,
  };
}

export function storeUserData(payload) {
  return {
    type: STORE_USER_DATA,
    payload,
  };
}

export function updateUserRolesAction(payload) {
  return {
    type: UPDATE_USER_ROLES,
    payload,
  };
}

export function createUserData(payload) {
  return {
    type: CREATE_USER_DATA,
    payload,
  };
}

export function storeRolesData(payload) {
  return {
    type: STORE_ROLES_DATA,
    payload,
  };
}

export function onFetchUserDataError({ error }) {
  return {
    type: ERROR_ON_FETCH_USER_DATA,
    error,
  };
}

export function onFetchRolesDataError({ error }) {
  return {
    type: ERROR_ON_FETCH_ROLES_DATA,
    error,
  };
}

export function onActionComplete() {
  return {
    type: CREATE_USER_COMPLETE,
    isLoading: false,
  };
}

export function toggleFilterPanelAction() {
  return {
    type: TOGGLE_FILTER_PANEL,
  };
}

export function resetUserAction() {
  return {
    type: RESET_USER_STATE,
  };
}

export function updateTableState({ tableState }) {
  return {
    type: UPDATE_TABLE_STATE,
    tableState,
  };
}
