import React from 'react';
import { Player as LottiePlayer } from '@lottiefiles/react-lottie-player';
import request from 'images/gif/requestFeature.json';
import { FormattedMessage } from 'react-intl';
import Button from 'components/TableFilters/NewButton';
import messages from '../messages';

const SuccessScreenContent = ({ onClose }) => (
  <div className="flex flex-col items-center px-[54px] pt-5 pb-10">
    <LottiePlayer autoplay loop src={request} className="h-44 w-44" />
    <div className="font-semibold leading-5 text-xl mb-4 mt-4 text-gray-900">
      <FormattedMessage {...messages?.helpful} />
    </div>
    <div className="font-normal leading-5.5 text-base text-gray-700 text-center">
      <FormattedMessage {...messages?.sharing_feedback} />
    </div>
    <div className="flex gap-1 justify-end pt-8 w-[90px]">
      <Button type="tertiary" size="lg" isFullWidth onClick={onClose}>
        <FormattedMessage {...messages?.close} />
      </Button>
    </div>
  </div>
);

export default SuccessScreenContent;
