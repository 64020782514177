/**
 *
 * Modal
 *
 */

import React, { memo, Fragment } from 'react';
import PropTypes from 'prop-types';
import { get, isFunction } from 'lodash';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';

import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

import Button from '../Button';

export function Modal(props) {
  const {
    show,
    children,
    title,
    onSuccess,
    size,
    successLabel,
    hasCancel,
    simple,
    onCancel,
    type,
    secondButtonType,
    showSeparator,
  } = props;
  const cancelFn = () => {
    if (onCancel) onCancel();
  };
  const sizeMap = {
    sm: 'max-w-lg',
    md: 'max-w-2xl',
    lg: 'max-w-4xl',
    xl: 'max-w-7xl',
  };
  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="fixed z-50 inset-0 overflow-y-auto" onClose={cancelFn}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={`relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4
              text-left shadow-xl transform transition-all sm:my-8 sm:align-middle
              sm:p-6 ${get(sizeMap, size)}`}
            >
              {simple ? (
                <>{children}</>
              ) : (
                <>
                  <div>
                    {title && (
                      <div className="flex justify-between">
                        <Dialog.Title as="h3" className="text-lg flex leading-6 font-semibold w-[350px] text-gray-800">
                          {title}
                        </Dialog.Title>
                        <button type="button" className={classNames('text-[#6C6F75] w-6 h-6 ml-4')} onClick={cancelFn}>
                          <span className="sr-only">Close</span>
                          <XIcon className="h-6 w-6 " aria-hidden="true" />
                        </button>
                      </div>
                    )}
                    <div className="mt-4 font-normal text-[#111827] ">{children}</div>
                    {showSeparator && <hr className="absolute mt-6 right-0 w-[100%]" />}
                  </div>
                  {hasCancel && (
                    <div className="mt-12 flex items-end justify-end space-x-3 ">
                      <Button type={type || 'transparent'} onClick={cancelFn}>
                        <FormattedMessage {...messages.cancel} />
                      </Button>
                      <Button
                        type={secondButtonType || 'success'}
                        onClick={() => {
                          if (isFunction(onSuccess)) onSuccess();
                        }}
                      >
                        {successLabel || <FormattedMessage {...messages.success} />}
                      </Button>
                    </div>
                  )}
                </>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

Modal.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  onSuccess: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  successLabel: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  onCancel: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  hasCancel: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
  simple: PropTypes.bool,
  deleted: PropTypes.bool,
  duplicate: PropTypes.bool,
  rename: PropTypes.bool,
  showSeparator: PropTypes.bool,
};

Modal.defaultProps = {
  show: false,
  size: 'md',
  hasCancel: true,
  onSuccess: false,
  simple: false,
  deleted: true,
  duplicate: true,
  rename: true,
  showSeparator: true,
};
export default memo(Modal);
