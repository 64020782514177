import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { get, map } from 'lodash';
import classNames from 'classnames';
import { faSortDown, faSort, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function Header(props) {
  const { headerGroups, sticky } = props;
  const handleSortBy = column => {
    // set sort desc, asc or none?
    if (column.sortBy) props.sort(column);
  };
  const renderSortIcon = column => {
    if (column.sortBy) {
      if (column.isSortedDesc === undefined)
        return <FontAwesomeIcon icon={faSort} className="cursor-pointer ml-1 w-3.5 h-3.5 text-slate-400" />;
      if (column.isSortedDesc)
        return <FontAwesomeIcon icon={faSortDown} className="cursor-pointer ml-1 w-3.5 h-3.5 text-slate-400" />;
      return <FontAwesomeIcon icon={faSortUp} className="cursor-pointer ml-1 w-3.5 h-3.5 text-slate-400" />;
    }
    return '';
  };

  return (
    <thead className={classNames('bg-slate-50 table-header-group', sticky && 'sticky top-0 z-10')}>
      {map(headerGroups, headerGroup => (
        <tr {...headerGroup.getHeaderGroupProps()} key={get(headerGroup, 'headers.0.id')} className="table-row">
          {map(headerGroup.headers, column => (
            <th
              scope="col"
              className={classNames(
                'py-3.5 pl-4 pr-3 sm:pl-6 text-left',
                'text-sm font-semibold text-gray-900 bg-gray-100',
                column.sticky && 'sticky shadow-[inset_-2px_0px_0px_0px_#E4E7ED]',
                column.sortBy && 'cursor-pointer',
                column.className || '',
              )}
              {...column.getHeaderProps({
                style: { width: column.width, left: column.sticky ? column.totalLeft : '' },
              })}
              key={column.id}
              onClick={() => handleSortBy(column)}
            >
              <div className="inline-flex">
                {column.render('Header')}
                <span>{renderSortIcon(column)}</span>
              </div>
            </th>
          ))}
        </tr>
      ))}
    </thead>
  );
}

Header.propTypes = {
  sticky: PropTypes.bool,
  headerGroups: PropTypes.array,
};

export default memo(Header);
