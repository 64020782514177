import React, { useContext } from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';

import { UserAuthContext } from 'contexts/UserAuthContext';
import { Navbar } from 'components/base';
import { get } from 'lodash';

const AuthenticatedRoute = () => {
  const { isAuthenticated, user, client, isAuthenticating } = useContext(UserAuthContext);
  const location = useLocation();
  if (user) {
    localStorage.setItem('adtv.accessToken', user?.accessToken);
  }
  const user_data = JSON.parse(localStorage.getItem('tv.lg'));
  const user_roles = get(user_data, "user['https://hasura.io/jwt/claims']['X-Hasura-Allowed-Roles']", null);

  if (!isAuthenticated && !isAuthenticating) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they logged in, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} />;
  }

  // TODO: forbidden access based on roles

  return (
    <>
      <ApolloProvider client={client}>
        <div className="flex bg-white">
          <div className="absolute z-50">
            <Navbar path={location?.path} user={user} isAuthenticated roles={user_roles} />
          </div>
          <div className="w-full pl-16 h-screen">
            <Outlet />
            {/* {error ? (
              <Error error={error} />
            ) : (
              <>{canLogin({ roles: [], path }) ? <Route {...props} /> : <Forbidden />}</>
            )} */}
          </div>
        </div>
      </ApolloProvider>
    </>
  );
};

export default AuthenticatedRoute;
