/**
 *
 * Forbidden
 *
 */

import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { HomeIcon, BanIcon } from '@heroicons/react/solid';

import Img from 'images/403.svg';
import { Button } from 'components/base';
import messages from './messages';

export function Forbidden() {
  const history = useNavigate();
  return (
    <div className="h-full bg-slate-50 relative">
      <div className="h-full flex justify-center items-center ">
        <div className="flex shadow-lg items-center p-6 rounded-md bg-white">
          <div className="flex flex-col space-y-8 flex-shrink-0 w-full md:w-1/2">
            <h2 className="flex md:text-5xl text-2xl font-semibold leading-8 align-middle items-center">
              <BanIcon className="w-7 h-7 text-red-600 inline md:hidden" /> <FormattedMessage {...messages.header} />
            </h2>
            <p className="text-lg break-word">
              <FormattedMessage {...messages.body} />
            </p>
            <div className="max-w-md">
              <Button type="primary" size="lg" LeadingIcon={HomeIcon} onClick={() => history('/')}>
                <FormattedMessage {...messages.home} />
              </Button>
            </div>
          </div>
          <img src={Img} alt="Forbidden" className="md:max-w-md md:w-1/2 hidden md:block -mr-20" />
        </div>
      </div>
    </div>
  );
}

Forbidden.propTypes = {};

export default memo(Forbidden);
