import React, { memo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import Select, { components } from 'react-select';
import { get } from 'lodash';

export const reactSelectDefaultStyles = {
  input: props => ({
    ...props,
    input: {
      boxShadow: 'none',
    },
  }),
  indicatorSeparator: props => ({
    ...props,
    display: 'none',
  }),
  placeholder: props => ({
    ...props,
    color: '#333940',
    opacity: 0.4,
    fontWeight: 400,
    fontSize: '14px',
  }),
  option: (props, option) => ({
    ...props,
    fontSize: '14px',
    backgroundColor: option.isSelected ? '#1d4ed8' : props.backgroundColor,
  }),
  singleValue: props => ({
    ...props,
    fontSize: '14px',
  }),
  multiValue: props => ({
    ...props,
    backgroundColor: '#DBEAFE',
    borderRadius: '4px',
    padding: '0px 8px 0px 8px',
    marginRight: '8px',
  }),
  multiValueLabel: props => ({
    ...props,
    color: '#1D4ED8',
    fontSize: '12px',
    fontWeight: '500',
  }),
  multiValueRemove: props => ({
    ...props,
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#1D4ED8',
    },
    color: '#1D4ED8',
  }),
  control: (provided, state) => {
    const borderColor = state && state.selectProps && state.selectProps.error ? '#b91c1c' : '#E5E7EB';
    return {
      ...provided,
      borderColor,
      boxShadow: 'none !important',
      '&:hover': {
        borderColor: 'none',
      },
    };
  },
};

export function DropdownIndicator(props) {
  const icon = props && props?.selectProps?.indicatorIcon;
  if (icon) {
    return (
      <components.DropdownIndicator {...props}>
        <FontAwesomeIcon icon={icon} className="w-6 h-6" />
      </components.DropdownIndicator>
    );
  }
  return (
    <components.DropdownIndicator {...props}>
      <FontAwesomeIcon icon={faAngleDown} className="w-6 h-6" />
    </components.DropdownIndicator>
  );
}

const SelectDropdown = props => {
  const {
    id,
    value,
    onChange,
    isDisabled,
    isLoading,
    isClearable,
    isSearchable,
    name,
    placeholder,
    options,
    primaryKey,
    labelKey,
    emptyLabel,
    customStyles,
    isMulti,
    icon,
    error,
    closeMenuOnSelect,
  } = props;

  const noOptionsMessage = () => emptyLabel;

  const formatOptionLabel = option => get(option, labelKey, option);
  const getOptionValue = option => get(option, primaryKey, option);

  const controlStyles = (provided, state) =>
    reactSelectDefaultStyles.control(provided, { ...state, selectProps: { ...state.selectProps, error } });

  return (
    <>
      <Select
        inputId={id}
        closeMenuOnSelect={closeMenuOnSelect}
        value={value}
        onChange={onChange}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isClearable={isClearable}
        isSearchable={isSearchable}
        name={name}
        placeholder={placeholder}
        options={options}
        getOptionValue={getOptionValue}
        getOptionLabel={formatOptionLabel}
        formatOptionLabel={formatOptionLabel}
        noOptionsMessage={noOptionsMessage}
        styles={{
          ...reactSelectDefaultStyles,
          ...customStyles,
          control: controlStyles,
        }}
        components={{
          DropdownIndicator,
        }}
        isMulti={isMulti}
        indicatorIcon={icon}
      />
      {error && <p className="text-red-700 mt-1 text-xs">{error}</p>}
    </>
  );
};

SelectDropdown.defaultProps = {
  name: 'dropdown',
  isSearchable: false,
  isMulti: false,
  closeMenuOnSelect: false,
};

export default memo(SelectDropdown);
