import { faArrowRightFromBracket } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { memo } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';
// import { handleEnterKeyPress } from 'utils/helpers';
import messages from './messages';
import Tooltip from './Tooltip';

const version = process.env.VERSION || 'testing';

export const ProfilePopoverContent = ({ name }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    navigate('/logout?skip=true');
  };

  return (
    <div className="border border-gray-200 pb-3 px-4 w-52 rounded-md shadow-[0px_4px_24px_rgba(17,24,39,0.08)]">
      <div className=" pt-4 pb-3">
        <div className="text-sm text-gray-900 font-semibold mb-1">{name}</div>
      </div>

      {/*  commented the below code will use in later implementation */}
      {/* <Tooltip text={<FormattedMessage {...messages.account_settings} id="settings" />}> */}
      {/*  <div */}
      {/*    className={classNames( */}
      {/*      'flex items-center py-2.5 cursor-pointer hover:bg-gray-100 px-2 w-full', */}
      {/*      accountSettings ? 'text-blue-700 bg-blue-50' : 'text-gray-700', */}
      {/*    )} */}
      {/*    onClick={showAccountSettings} */}
      {/*    onKeyDown={e => handleEnterKeyPress(e, showAccountSettings)} */}
      {/*    role="presentation" */}
      {/*  > */}
      {/*    <FontAwesomeIcon icon={faGear} className="text-lg" /> */}

      {/*    <span className="ml-2 text-sm truncate max-w-[135px]"> */}
      {/*      <FormattedMessage {...messages.account_settings} /> */}
      {/*    </span> */}
      {/*  </div> */}
      {/* </Tooltip> */}
      <Tooltip text={<FormattedMessage {...messages.signOut} />}>
        <div
          className={classNames(
            'flex items-center text-gray-700  py-2.5 cursor-pointer hover:bg-gray-100 px-2 truncate w-full',
          )}
          onClick={handleLogout}
          onKeyDown={handleLogout}
          role="presentation"
        >
          <FontAwesomeIcon icon={faArrowRightFromBracket} className="text-lg" />

          <span className="ml-2 text-sm truncate max-w-[135px]" data-testid="signOut">
            <FormattedMessage {...messages.signOut} />
          </span>
        </div>
      </Tooltip>
      <div className="text-sm text-gray-500 mt-5">
        <FormattedMessage {...messages.version} />
        <span className="ml-1">{version}</span>
      </div>
    </div>
  );
};

ProfilePopoverContent.propTypes = {};

export default memo(ProfilePopoverContent);
