/**
 *
 * NotFound
 *
 */

import React, { memo } from 'react';

import { Loading } from 'components/base';

export function LoadingPage() {
  return (
    <div className="h-screen bg-slate-50 relative">
      <div className="h-full flex justify-center items-center ">
        <Loading />
      </div>
    </div>
  );
}

LoadingPage.propTypes = {};

export default memo(LoadingPage);
