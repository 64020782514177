/* eslint-disable no-param-reassign */
import React from 'react';
import Auth, { PLATFORM_ADMIN, TENANT_ADMIN } from 'utils/auth';
import { FormattedMessage } from 'react-intl';
import { filterItems } from 'components/base/Navbar/items';

import {
  faUsers,
  faSliders,
  faMagnifyingGlassArrowRight,
  faGear,
  faGroupArrowsRotate,
  faArrowRightArrowLeft,
} from '@fortawesome/pro-light-svg-icons';
import messages from './messages';

export const getItems = () => {
  const roles = Auth.roles || [];
  const items = [
    {
      icon: faUsers,
      name: <FormattedMessage {...messages?.users} />,
      summary: <FormattedMessage {...messages?.adminConsoleSummary} />,
      route: './users',
      roles: [PLATFORM_ADMIN, TENANT_ADMIN],
    },
    {
      icon: faSliders,
      name: <FormattedMessage {...messages?.forms} />,
      summary: <FormattedMessage {...messages?.formsSummary} />,
      route: './form',
      roles: [PLATFORM_ADMIN, TENANT_ADMIN],
    },
    {
      icon: faGroupArrowsRotate,
      name: <FormattedMessage {...messages?.groups} />,
      summary: <FormattedMessage {...messages?.groupsSummary} />,
      route: './groups',
      roles: [PLATFORM_ADMIN, TENANT_ADMIN],
    },
    {
      icon: faMagnifyingGlassArrowRight,
      name: <FormattedMessage {...messages?.notification_tracker} />,
      summary: <FormattedMessage {...messages?.notification_trackerSummary} />,
      route: './notifications',
      roles: [PLATFORM_ADMIN, TENANT_ADMIN],
    },
    {
      icon: faArrowRightArrowLeft,
      name: <FormattedMessage {...messages?.transactions} />,
      summary: <FormattedMessage {...messages?.transactionsSummary} />,
      route: './transactions',
      roles: [PLATFORM_ADMIN, TENANT_ADMIN],
    },
    {
      icon: faGear,
      name: <FormattedMessage {...messages?.configurations} />,
      summary: <FormattedMessage {...messages?.configurationsSummary} />,
      route: './tenant/:tenantId',
      roles: [PLATFORM_ADMIN, TENANT_ADMIN],
    },
  ];
  return filterItems({ items, roles });
};
