import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFilters, useRowSelect, useSortBy, useTable } from 'react-table/dist/react-table.production.min';
import { map } from 'lodash';
import classNames from 'classnames';
import Header from './Header';
import Body from './Body';

export function Table(props) {
  const { columns, data, onSelect, sticky, sortBy, onRowClick } = props;
  const tableInstance = useTable(
    { columns, data, manualFilter: true, initialState: { sortBy } },
    useFilters,
    useSortBy,
    useRowSelect,
  );
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows, selectedFlatRows, setSortBy } =
    tableInstance;

  useEffect(() => {
    if (onSelect) {
      onSelect(map(selectedFlatRows, 'original.id'));
    }
  }, [selectedFlatRows]);

  const sort = column => {
    let desc;
    if (column.isSortedDesc) desc = false;
    else desc = true;
    setSortBy([{ id: column.id, desc }, ...sortBy]);
  };

  return (
    <div
      className={classNames(
        ' -mx-4 mt-4 overflow-x-auto overflow-y-auto max-h-[78vh] ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0',
        sticky ? '' : 'overflow-hidden',
      )}
    >
      <table className={classNames('w-full', 'table-fixed')} {...getTableProps()}>
        <Header headerGroups={headerGroups} sticky={sticky} sort={sort} />
        <Body rows={rows} getTableBodyProps={getTableBodyProps} prepareRow={prepareRow} onRowClick={onRowClick} />
      </table>
    </div>
  );
}

Table.propTypes = {
  sticky: PropTypes.bool,
  onSelect: PropTypes.func,
  columns: PropTypes.array,
  data: PropTypes.array,
  sortBy: PropTypes.array,
  onRowClick: PropTypes.func,
};

Table.defaultProps = {
  sticky: false,
  columns: [],
  data: [],
  sortBy: [],
};

export default memo(Table);
