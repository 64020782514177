import classNames from 'classnames';
import React, { useState, useMemo, useEffect, useRef } from 'react';
import TalviewLogoText from 'images/talview_colored_black.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import Auth, { TENANT_ADMIN, ROLE_CONTENT_MANAGER, PLATFORM_ADMIN } from 'utils/auth';
import { faBars } from '@fortawesome/pro-regular-svg-icons';
import { ActionProvider } from 'contexts/ActionContext';
import { NotificationsPopover } from './NotificationsPopover';
import { NavigationItems } from './NavigationItems';
import { ProfilePopover } from '../ProfilePopover/index';
import { Admin } from './Admin';
import { GetHelp } from './GetHelp';
import RequestFeature from './RequestFeature';
import ReportIssue from './ReportIssue';

export function SideNavigation({ items }) {
  const userData = () => {
    const getUserData = JSON.parse(localStorage.getItem('tv.lg'));
    const email = _.get(getUserData, 'user.email');
    const name = _.get(
      getUserData,
      `user.firebase.sign_in_attributes['http://schemas.microsoft.com/identity/claims/displayname']`,
    );
    return { email, name };
  };
  const [open, setOpen] = useState(false);
  const [accountSettings, toggleAccountSettings] = useState(false);
  const [openReportIssue, toggleReportIssue] = useState(false);
  const [requestFeature, toggleRequestFeature] = useState(false);
  const sidebarRef = useRef(null);

  const showAdmin = useMemo(() => {
    const roles = Auth.roles || [];
    const allowedRoles = [TENANT_ADMIN, ROLE_CONTENT_MANAGER, PLATFORM_ADMIN];
    return roles.some(role => allowedRoles.includes(role));
  }, [Auth]);
  const showAccountSettings = () => {
    toggleAccountSettings(true);
  };
  const hideReportIssue = () => {
    toggleReportIssue(false);
  };
  const handleReportSuccess = () => {
    toggleReportIssue(false);
  };

  const showReportIssue = () => {
    toggleReportIssue(true);
  };

  const hideRequestFeature = () => {
    toggleRequestFeature(false);
  };
  const showRequestFeature = () => {
    toggleRequestFeature(true);
  };
  const handleClickOutside = event => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    const listener = document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [open]);

  return (
    <>
      <div
        className={classNames(
          'bg-white flex flex-col h-screen px-3',
          open ? 'w-44' : 'w-[70px]',
          'border-r border-gray-200 border-t border-t-gray-100',
          'transition-[width] duration-150 ',
        )}
        ref={sidebarRef}
      >
        <div className=" flex-1 flex-col">
          <div className="mt-3">
            <div className="flex items-center justify-items-center">
              <div>
                <FontAwesomeIcon
                  icon={faBars}
                  className={classNames(
                    open ? 'bg-blue-100' : 'hover:bg-gray-100',
                    'h-6 w-6 text-gray-500 rounded-full hover:bg-gray-100 p-3 block cursor-pointer',
                  )}
                  aria-hidden="true"
                  onClick={() => setOpen(prev => !prev)}
                  data-testid="hamburger-icon"
                />
              </div>
              {open && (
                <div className="flex items-center ml-4">
                  <img className="h-8 w-auto" src={TalviewLogoText} alt="Talview" />
                </div>
              )}
            </div>
          </div>
          <div className={classNames(`${open ? 'flex justify-start ' : 'flex'}`)}>
            <NavigationItems expanded={open} items={items} />
          </div>
          {showAdmin && (
            <div className="mt-4">
              <Admin expanded={open} />
            </div>
          )}
        </div>
        <div className="pt-5 border-t">
          <GetHelp
            expanded={open}
            openReportIssue={openReportIssue}
            toggleReportIssue={toggleReportIssue}
            hideReportIssue={hideReportIssue}
            handleReportSuccess={handleReportSuccess}
            showReportIssue={showReportIssue}
            requestFeature={requestFeature}
            hideRequestFeature={hideRequestFeature}
            showRequestFeature={showRequestFeature}
          />
          <div className="relative">
            <NotificationsPopover expanded={open} />
          </div>
          <div>
            <ProfilePopover
              expanded={open}
              showAccountSettings={showAccountSettings}
              accountSettings={accountSettings}
              data={userData()}
            />
          </div>
        </div>
      </div>

      {openReportIssue && (
        <ActionProvider>
          <ReportIssue open handleClose={hideReportIssue} handleOnSuccess={handleReportSuccess} />
        </ActionProvider>
      )}
      {requestFeature && (
        <ActionProvider>
          <RequestFeature open handleClose={hideRequestFeature} />
        </ActionProvider>
      )}
      {/* {accountSettings && <AccountSettingsContainer open handleClose={hideAccountSettings} />} */}
    </>
  );
}

SideNavigation.propTypes = {};

export default SideNavigation;
