/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import history from 'utils/history';
import languageProviderReducer from 'containers/LanguageProvider/reducer';
import { errorReducer, savedReducer, taskReducer, userAuthReducer } from 'containers/App/reducer';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducers = {}) {
  return combineReducers({
    language: languageProviderReducer,
    router: connectRouter(history),
    tasks: taskReducer,
    error: errorReducer,
    saved: savedReducer,
    userAuth: userAuthReducer,
    ...injectedReducers,
  });
}
