/**
 *
 * Pagination
 *
 */

import React, { useState, useEffect } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import PropTypes from 'prop-types';
import { get, ceil } from 'lodash';

import { createStructuredSelector } from 'reselect';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { makeSelectSaved } from 'containers/App/selectors';
import { saveAction } from 'containers/App/actions';

import messages from './messages';

export function Pagination(props) {
  const { pageSize, onCurrentChange, onSizeChange, currentPage, saved, total, type, summary, saveSize } = props;

  const [currentSize] = useState(pageSize || parseInt(get(saved, 'pageSize'), 10) || 25);
  const [current, setCurrent] = useState(1);

  useEffect(() => {
    if (onSizeChange) onSizeChange(currentSize);
    if (saveSize) saveSize(currentSize);
  }, [currentSize]);

  useEffect(() => {
    setCurrent(() => currentPage);
  }, [currentPage]);

  useEffect(() => {
    if (onCurrentChange && current !== currentPage) onCurrentChange(current);
  }, [current]);

  const pages = ceil(total / pageSize);

  return (
    <div className="bg-white px-4 py-3 flex items-center justify-between  sm:px-6">
      <div className="flex-1 flex justify-between sm:hidden">
        <button
          type="button"
          onClick={() => setCurrent(Math.max(currentPage - 1, 1))}
          className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm
          font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        >
          <FormattedMessage {...messages.previous} />
        </button>
        <button
          type="button"
          onClick={() => setCurrent(Math.max(currentPage + 1, pages))}
          className="relative inline-flex items-center px-4 py-2 border border-gray-300
          text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        >
          <FormattedMessage {...messages.next} />
        </button>
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div>
          {summary && (
            <p className="text-sm text-gray-700 font-medium">
              Showing <span className="font-bold">{Math.max((current - 1) * pageSize, 1)}</span> to{' '}
              <span className="font-bold">{current * pageSize}</span> of <span className="font-bold">{total}</span>{' '}
              {type}
            </p>
          )}
        </div>
        <div>
          <div className="text-sm text-[#333940]  relative inline-flex z-0">
            <p className=" gap-1 flex justify-center items-center">
              <span>{Math.max((current - 1) * pageSize, 1)}</span> - <span>{current * pageSize}</span> of{' '}
              <span>{total}</span>{' '}
            </p>
            <nav
              className=" relative items-center justify-center inline-flex rounded-md space-x-px"
              aria-label="Pagination"
            >
              <button
                type="button"
                onClick={() => setCurrent(Math.max(currentPage - 1, 1))}
                disabled={current <= 1}
                className={`relative inline-flex items-center px-4 py-2
                  text-lg font-large
                  ${current <= 1 ? 'text-[#E1E3E5]' : 'text-[#333940]'}
              `}
              >
                <span className="sr-only">Previous</span>
                <ChevronLeftIcon className="h-6 w-6" aria-hidden="true" />
              </button>
              <button
                type="button"
                onClick={() => setCurrent(Math.min(currentPage + 1, pages))}
                disabled={current >= pages}
                className={`relative inline-flex items-center px-2 py-2
                   text-lg font-large
                   ${current >= pages ? 'text-[#E1E3E5]' : 'text-[#333940]'}
              `}
              >
                <span className="sr-only">Next</span>
                <ChevronRightIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}

Pagination.propTypes = {
  total: PropTypes.number,
  saved: PropTypes.object,
  saveSize: PropTypes.func,
  type: PropTypes.string,
  onSizeChange: PropTypes.func,
  onCurrentChange: PropTypes.func,
  pageSize: PropTypes.number,
  summary: PropTypes.bool,
  pageSizes: PropTypes.arrayOf(PropTypes.number),
  currentPage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

const mapStateToProps = createStructuredSelector({
  saved: makeSelectSaved(),
});

export function mapDispatchToProps(dispatch) {
  return {
    saveSize: (key, data) => dispatch(saveAction({ key, data })),
    dispatch,
  };
}

Pagination.defaultProps = {
  summary: false,
  pageSize: 10,
  type: 'results',
  total: 10,
  currentPage: 1,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(Pagination);
