/**
 *
 * GetHelp
 *
 */

import PropTypes from 'prop-types';
import React, { memo, Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { faCircleQuestion } from '@fortawesome/pro-light-svg-icons';
import { NavigationIcon } from '../NavigationIcon';
import Content from './Content';

export function GetHelp(props) {
  const { expanded } = props;
  return (
    <div>
      <Popover className="relative">
        <Popover.Button className="outline-none">
          <NavigationIcon icon={faCircleQuestion} label="GetHelp" isChevronRight expanded={expanded} />
        </Popover.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-100"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Popover.Panel className={classNames('absolute bg-white bottom-0', expanded ? 'left-44' : 'left-16')}>
            <Content {...props} />
          </Popover.Panel>
        </Transition>
      </Popover>
    </div>
  );
}

GetHelp.propTypes = {
  expanded: PropTypes.bool,
};

export default memo(GetHelp);
