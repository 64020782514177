/**
 *
 * Notification
 *
 */

import React, { memo, Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { get } from 'lodash';
import { Transition } from '@headlessui/react';
import { BadgeCheckIcon, BanIcon, ExclamationIcon, InformationCircleIcon, XIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import { toast } from 'react-toastify';

export function Notification(props) {
  const { header, children, timeout, primary, secondary, onClose, type, showIcon, ...rest } = props;
  const [show, setShow] = useState(true);

  useEffect(() => {
    let timer = null;
    if (timeout) {
      timer = setTimeout(() => {
        setShow(false);
        if (onClose) onClose();
      }, timeout);
    }
    return () => {
      if (timer) clearTimeout(timer);
      if (onClose) onClose();
    };
  });

  const dataMap = {
    success: { Icon: BadgeCheckIcon, color: 'text-emerald-400', borderColor: 'border-lime-700' },
    danger: { Icon: BanIcon, color: 'text-red-400', borderColor: 'border-red-700' },
    warning: { Icon: ExclamationIcon, color: 'text-amber-400', borderColor: 'border-yellow-700' },
    info: { Icon: InformationCircleIcon, color: 'text-indigo-400', borderColor: 'border-indigo-400' },
    default: { Icon: InformationCircleIcon, color: 'text-gray-500', borderColor: 'border-gray-400' },
  };

  const Icon = showIcon ? get(dataMap, `${type}.Icon`) : null;
  const iconHeight = !header || !children ? 'h-6 w-6' : 'h-12 w-12';

  return (
    <>
      <div
        aria-live="assertive"
        className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
      >
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              style={{ minHeight: '59px' }}
              className={classNames(
                'max-w-xs w-full bg-white shadow pointer-events-auto ring-1' +
                  'ring-black ring-opacity-5 overflow-hidden border-l-4' +
                  ' flex items-center justify-center',
                get(dataMap, `${type}.borderColor`),
              )}
              {...rest}
            >
              <div className="flex items-start p-1 w-full">
                {showIcon && Icon && (
                  <div className="flex-shrink-0 p-1">
                    <Icon className={`${iconHeight} ${get(dataMap, `${type}.color`)}`} aria-hidden="true" />
                  </div>
                )}
                <div className="ml-3 flex-1 space-y-1 p-1 my-auto">
                  {header && <p className="text-sm font-medium text-gray-900">{header}</p>}
                  {children && <p className="text-sm text-gray-900">{children}</p>}
                </div>
                {!primary && !secondary ? (
                  <div className="ml-4 flex-shrink-0 flex my-auto">
                    <button
                      type="button"
                      className="bg-white inline-flex"
                      onClick={() => {
                        setShow(false);
                        if (onClose) onClose();
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5 text-gray-900 hover:bg-blue-50 rounded-full" aria-hidden="true" />
                    </button>
                  </div>
                ) : (
                  <div className="flex flex-col divide-y divide-gray-200 border-l border-grey-50 h-14">
                    {primary && (
                      <div className="h-0 flex-1 flex align-middle justify-center items-center">
                        <button
                          type="button"
                          className={`w-full border border-transparent rounded-none rounded-tr-lg p-1.5
                              flex items-center justify-center text-sm focus:outline-none focus:z-10 focus:ring-2
                              font-medium ${get(dataMap, `${primary.type}.color`)}
                              hover:${get(dataMap, `${primary.type}.color`)}
                              focus:ring-${get(dataMap, `${primary.type}.color`)}`}
                          onClick={() => {
                            setShow(false);
                            if (primary.onClick) primary.onClick();
                          }}
                        >
                          {primary.label}
                        </button>
                      </div>
                    )}

                    {secondary && (
                      <div className="h-0 flex-1 flex align-middle justify-center items-center">
                        <button
                          type="button"
                          className={`w-full border border-transparent rounded-none rounded-tr-lg p-1.5
                              flex items-center justify-center text-sm focus:outline-none focus:z-10 focus:ring-2
                              font-medium ${get(dataMap, `${secondary.type}.color`)}
                              hover:${get(dataMap, `${secondary.type}.color`)}
                              focus:ring-${get(dataMap, `${secondary.type}.color`)}`}
                          onClick={() => {
                            setShow(false);
                            if (secondary.onClick) secondary.onClick();
                          }}
                        >
                          {secondary.label}
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
}

Notification.propTypes = {
  header: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  timeout: PropTypes.number,
  type: PropTypes.oneOf(['success', 'info', 'warning', 'danger']),
  primary: PropTypes.shape({
    type: PropTypes.oneOf(['success', 'info', 'warning', 'danger']),
    label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    onClick: PropTypes.func,
  }),
  onClose: PropTypes.func,
  secondary: PropTypes.shape({
    type: PropTypes.oneOf(['success', 'info', 'warning', 'danger', 'default']),
    label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    onClick: PropTypes.func,
  }),
  showIcon: PropTypes.bool,
};

Notification.defaultProps = {
  type: 'info',
  timeout: 3000,
  showIcon: false,
};

export const triggerToast = arg => {
  const { setting = {}, ...props } = arg;
  const defaultSettings = {
    theme: 'light',
    icon: false,
    closeButton: false,
    hideProgressBar: true,
    autoClose: 3000,
    ...setting,
    position: 'top-right',
  };

  toast(<Notification {...props} />, { ...defaultSettings });
};

export default memo(Notification);
