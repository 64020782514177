/**
 *
 * Photo
 *
 */

import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { words, pad, get } from 'lodash';

import classNames from 'classnames';
import { getImage } from './api';

export function Photo(props) {
  const { id, name, children, type, status, size } = props;
  const [url, setUrl] = useState('');
  const i = pad(
    words(name)
      .map(n => n.substring(0, 1))
      .join('')
      .substring(0, 2),
    2,
  ).toUpperCase();
  const bgMap = {
    default: 'bg-indigo-500',
    select: 'bg-lime-600',
    reject: 'bg-red-600',
    hold: 'bg-amber-600',
    1: 'bg-zinc-700',
    2: 'bg-blue-400',
    3: 'bg-indigo-400',
  };
  useEffect(() => {
    if (type === 'candidate' && id) {
      getImage({ id }).then(u => setUrl(u));
    }
  }, [id]);

  const sizeMap = {
    sm: 'h-5 w-5 text-[8px] font-light leading-none',
    md: 'h-8 w-8 text-xs font-light leading-none',
    lg: 'h-10 w-10 text-sm font-medium leading-1',
    xs: 'h-5 w-5 text-[6px] font-light leading-none',
  };
  return !url ? (
    <div
      className={classNames(
        'flex items-center align-middle',
        get(bgMap, status, 'bg-gray-500'),
        'justify-center rounded-full',
        get(sizeMap, size, 'md'),
      )}
    >
      <span className="text-white">{i}</span>
      {children}
    </div>
  ) : (
    <button className="flex justify-center items-center inline-block" type="button">
      <img src={url} alt="profile" className={classNames('rounded-full', get(sizeMap, size, 'md'))} />
      <div>{children}</div>
    </button>
  );
}

Photo.propTypes = {
  id: PropTypes.number,
  status: PropTypes.oneOf(['default', 'select', 'reject', 'hold', 1, 2, 3]),
  name: PropTypes.string,
  size: PropTypes.oneOf(['xs', 'md', 'sm', 'lg']),
  type: PropTypes.oneOf(['user', 'candidate', 'default']),
};
Photo.defaultProps = {
  name: '  ',
  size: 'md',
  status: 'default',
};

export default memo(Photo);
