/**
 *
 * App.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 */

import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
import { map } from 'lodash';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import Maintenance from 'components/pages/Maintenance';
import AuthenticatedRoute from 'components/AuthenticatedRoute';

import { UserAuthProvider } from 'contexts/UserAuthContext';
import ErrorPage from 'components/pages/Error';

import { GrowthBookProvider } from '@growthbook/growthbook-react';
import { growthbook } from 'utils/growthBook';

import { ToastContainer } from 'react-toastify';
import { privateRoutes, publicRoutes } from './routeMap';
import { makeSelectError } from './selectors';

import 'base.css';

export function App(props) {
  const { error } = props;
  const location = useLocation();

  useEffect(() => {
    // Load features asynchronously when the app renders
    growthbook.loadFeatures();
  }, []);

  useEffect(() => {
    if (ReactGA) {
      ReactGA.set({ page: location.pathname });
      ReactGA.pageview(location.pathname);
    }
  }, [location]);
  if (process.env.MAINTAINANCE_MODE === 'true') {
    return <Maintenance />;
  }
  if (error?.message) {
    return <ErrorPage error={error} id={error.id} />;
  }
  return (
    <GrowthBookProvider growthbook={growthbook}>
      <UserAuthProvider>
        <Routes>
          {map(publicRoutes, ({ key, path, element: Element }) => (
            <Route path={path} element={<Element />} key={key} />
          ))}
          <Route element={<AuthenticatedRoute />}>
            {map(privateRoutes, ({ key, path, element: Element }) => (
              <Route path={path} element={<Element />} key={key} />
            ))}
          </Route>
        </Routes>
        <ToastContainer style={{ width: 'auto', zIndex: 100, position: 'relative' }} />
      </UserAuthProvider>
    </GrowthBookProvider>
  );
}
const mapStateToProps = createStructuredSelector({
  error: makeSelectError(),
});

const withConnect = connect(mapStateToProps);
export default compose(withConnect)(App);
