export const FETCH_USER_DATA = 'app/users/FETCH_USER_DATA';
export const STORE_USER_DATA = 'app/users/STORE_USER_DATA';
export const UPDATE_USER_ROLES = 'app/users/UPDATE_USER_ROLES';
export const UPDATE_USER_ROLES_COMPLETE = 'app/users/UPDATE_USER_ROLES_COMPLETE';
export const STORE_ROLES_DATA = 'app/users/STORE_ROLES_DATA';
export const ERROR_ON_FETCH_USER_DATA = 'app/users/ERROR_ON_FETCH_USER_DATA';
export const ERROR_ON_FETCH_ROLES_DATA = 'app/users/ERROR_ON_FETCH_ROLES_DATA';
export const CREATE_USER_DATA = 'app/users/CREATE_USER_DATA';
export const CREATE_USER_COMPLETE = 'app/users/CREATE_USER_COMPLETE';
export const TOGGLE_FILTER_PANEL = 'app/users/TOGGLE_FILTER';
export const RESET_USER_STATE = 'app/users/RESET_USER_STATE';
export const STORE_TABLE_STATE = 'app/users/STORE_TABLE_STATE';
export const PARSE_TABLE_STATE = 'app/users/PARSE_TABLE_STATE';
export const UPDATE_TABLE_STATE = 'app/users/UPDATE_TABLE_STATE';
