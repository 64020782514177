export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const constructFullName = (firstName, lastName, middleName, isOnlyFirstName) => {
  if (firstName || lastName) {
    if (isOnlyFirstName) {
      return capitalizeFirstLetter(firstName);
    }
    return `${capitalizeFirstLetter(firstName)} ${
      middleName ? `${capitalizeFirstLetter(middleName)} ` : ''
    }${capitalizeFirstLetter(lastName)}`;
  }
  return '';
};

export default function getInitial(data, isOnlyFirstName) {
  let fullName = '';
  const name = (data?.name || '').trim();
  if (name) {
    fullName = name;
  } else {
    const firstName = (data?.first_name || '').trim();
    const middleName = (data?.middle_name || '').trim();
    const lastName = (data?.last_name || '').trim();
    fullName = constructFullName(firstName, lastName, middleName, isOnlyFirstName);
  }
  return fullName || data?.email;
}
