/* eslint-disable no-param-reassign */
import { memoize, map, filter, isArray, isNull, reject, get } from 'lodash';
import { PLATFORM_ADMIN, TENANT_ADMIN } from 'utils/auth';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { faNetworkWired, faFilePen, faCalendarCircleUser } from '@fortawesome/pro-light-svg-icons';
import message from './messages';

export const filterItems = ({ items, roles }) =>
  reject(
    map(items, item => {
      const hasItem =
        get(item, 'roles', []).filter(e => roles && roles.includes(e)).length > 0 &&
        !(get(item, 'excludes', []).filter(e => roles && roles.includes(e)).length > 0);
      if (isArray(item.subItems)) {
        item.subItems = filter(
          item.subItems,
          subItem => subItem.roles.filter(e => roles && roles.includes(e)).length > 0,
        );
      }
      return hasItem ? item : null;
    }),
    isNull,
  );

export const getItems = ({ roles }) => {
  const items = [
    {
      label: <FormattedMessage {...message.Workflows} />,
      icon: faNetworkWired,
      name: 'Workflows',
      index: '/workflows',
      roles: [PLATFORM_ADMIN, TENANT_ADMIN],
    },
    {
      label: <FormattedMessage {...message.Interviews} />,
      icon: faFilePen,
      name: 'Interviews',
      index: '/interviews',
      roles: [PLATFORM_ADMIN, TENANT_ADMIN],
    },
    {
      label: <FormattedMessage {...message.Availability} />,
      icon: faCalendarCircleUser,
      name: 'Availability',
      index: '/availability',
      roles: [PLATFORM_ADMIN, TENANT_ADMIN],
    },
  ];
  return filterItems({ items, roles });
};

export default memoize(getItems);
