/*
 * Nav Messages
 *
 * This contains all the text for the Nav container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.base.Navbar';

export default defineMessages({
  taxonomy: {
    id: `${scope}.taxonomy`,
    defaultMessage: 'Taxonomy',
  },
  search: {
    id: `${scope}.search`,
    defaultMessage: 'Search',
  },
  banks: {
    id: `${scope}.banks`,
    defaultMessage: 'Question Banks',
  },
  library: {
    id: `${scope}.library`,
    defaultMessage: 'Library',
  },
  settings: {
    id: `${scope}.settings`,
    defaultMessage: 'Settings',
  },
  notifications: {
    id: `${scope}.notifications`,
    defaultMessage: 'Notifications',
  },
  profile: {
    id: `${scope}.profile`,
    defaultMessage: 'Profile',
  },
  logout: {
    id: `${scope}.logout`,
    defaultMessage: 'Logout',
  },
  form: {
    id: `${scope}.form`,
    defaultMessage: 'Form',
  },
  user: {
    id: `${scope}.user`,
    defaultMessage: 'User',
  },
  tenant: {
    id: `${scope}.tenant`,
    defaultMessage: 'Tenant',
  },
  Workflows: {
    id: `${scope}.workflows`,
    defaultMessage: 'Workflows',
  },
  Interviews: {
    id: `${scope}.interviews`,
    defaultMessage: 'Interviews',
  },
  Availability: {
    id: `${scope}.availability`,
    defaultMessage: 'Availability',
  },
  Admin: {
    id: `${scope}.admin`,
    defaultMessage: 'Admin',
  },
});
