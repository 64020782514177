/*
 * Nav Messages
 *
 * This contains all the text for the Nav container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.Dropdown';

export default defineMessages({
  options: {
    id: `${scope}.options`,
    defaultMessage: 'Options',
  },
});
