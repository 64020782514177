import React, { memo, Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { faUserGear } from '@fortawesome/pro-light-svg-icons';
import { useNavigate } from 'react-router-dom';
import { NavigationIcon } from '../NavigationIcon';
import Content from './Content';
import { getItems } from './items';

export function Admin(props) {
  const { expanded } = props;
  const apps = getItems();

  const navigate = useNavigate();
  const handleClick = path => {
    if (path) {
      navigate(`/${path}`);
    }
  };

  return (
    <Popover className="relative">
      <Popover.Button className="outline-none">
        <NavigationIcon icon={faUserGear} name="admin" label="Admin" isChevronRight expanded={expanded} />
      </Popover.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-100"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Popover.Panel className={classNames('absolute bg-white top-0', expanded ? 'left-44' : 'left-16')}>
          <Content handleClick={handleClick} apps={apps} />
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}

Admin.propTypes = {};

export default memo(Admin);
