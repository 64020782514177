/**
 *
 * DateRangePick
 *
 */

import React, { memo, useState, useEffect, useRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import { CalendarIcon, XIcon } from '@heroicons/react/solid';
import { DateTime } from 'luxon';
import { filter, get } from 'lodash';
import { Transition } from '@headlessui/react';

import classNames from 'classnames';
import Modal from '../Modal';
import Calendar from '../Calendar';
import Input from '../Input';

export function DateRangePicker(props) {
  const { value, onChange, placeholder, label, disabled, nullable, layout } = props;
  const [val, setVal] = useState(filter(value, Boolean));

  const isMounted = useRef(false);
  const toStr = get(val, '1') ? DateTime.fromISO(val[1]).toFormat('yyyy-MM-dd') : '';
  const valStr = val && val.length === 2 ? `${DateTime.fromISO(val[0]).toFormat('yyyy-MM-dd')} - ${toStr}` : null;
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (isMounted.current) {
      if (onChange) onChange(val);
    } else {
      isMounted.current = true;
    }
  }, [val]);
  const onFromChange = from => setVal([from, get(val, '1')]);
  const onToChange = to => {
    setVal([get(val, '0'), to]);
    setOpen(false);
  };
  return (
    <div className="flex w-fit relative ">
      <Input
        placeholder={placeholder}
        defaultValue={valStr}
        label={label}
        disabled={disabled}
        readOnly="readonly"
        leadingIconOnClick={() => {
          if (!disabled) setOpen(!open);
        }}
        LeadingIcon={CalendarIcon}
        trailingIconOnClick={() => {
          if (!disabled && nullable && val) setVal([]);
        }}
        TrailingIcon={!disabled && nullable && val ? XIcon : null}
        type="text"
      />
      {layout === 'full' ? (
        <Modal simple show={open} onCancel={() => setOpen(false)} size="lg">
          <div className="flex focus:outline-none space-x-2">
            <Calendar value={get(val, '0', DateTime.now().toISODate())} onChange={onFromChange} prependLabel="From: " />

            <Calendar
              currentValue={get(val, '1', DateTime.now().plus({ month: 1 }).toISODate())}
              onChange={onToChange}
              prependLabel="To: "
            />
          </div>
        </Modal>
      ) : (
        <Transition.Root show={open}>
          <Transition
            as={Fragment}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <div className="overflow-hidden">
              <div
                className={classNames(
                  'absolute flex rounded-lg shadow-lg ring-1 ring-black ring-opacity-5',
                  ' focus:outline-none mt-12 space-x-2 z-10 h-84',
                  layout !== 'left' ? `right-0` : 'left-0',
                )}
              >
                <Calendar
                  value={get(val, '0', DateTime.now().toISODate())}
                  onChange={onFromChange}
                  prependLabel="From: "
                />

                <Calendar
                  currentValue={get(val, '1', DateTime.now().plus({ month: 1 }).toISODate())}
                  onChange={onToChange}
                  prependLabel="To: "
                />
              </div>
            </div>
          </Transition>
        </Transition.Root>
      )}
    </div>
  );
}

DateRangePicker.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  nullable: PropTypes.bool,
  layout: PropTypes.oneOf(['left', 'right', 'full']),
};
DateRangePicker.defaultProps = {
  value: [null, null],
  nullable: false,
  layout: 'left',
};
export default memo(DateRangePicker);
