import { getAuth, signOut } from 'firebase/auth';
import { initializeApp } from 'firebase/app';

const search = new URLSearchParams(window.location.search);
const appName = `${search.get('appName') || 'admin'}-${process.env.BUILD_ENV}`;

const config = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_API_DOMAIN,
};
const app = initializeApp(config, { name: appName });

const auth = getAuth(app);

export { signOut, auth, app };
