import { defineMessages } from 'react-intl';

export const scope = 'app.components.ProfilePopoverContent';

export default defineMessages({
  account_settings: {
    id: `${scope}.account_settings`,
    defaultMessage: 'Account Settings',
  },
  signOut: {
    id: `${scope}.signOut`,
    defaultMessage: 'Sign out',
  },
  version: {
    id: `${scope}.version`,
    defaultMessage: 'Version',
  },
});
