import { Popover, Transition } from '@headlessui/react';
import classNames from 'classnames';
import React, { memo, Fragment } from 'react';
import _ from 'lodash';
import { NavigationIcon } from '../SideNavBar/NavigationIcon';
import ProfilePopoverContent from './ProfilePopoverContent';

export function ProfilePopover(props) {
  const { expanded, showAccountSettings, accountSettings, data } = props;

  return (
    <Popover className={classNames('relative')}>
      <Popover.Button className="outline-none">
        <NavigationIcon
          label={_.get(data, 'name')}
          isChevronRight
          expanded={expanded}
          customTooltipContent="Profile"
          user={data}
          description={_.get(data, 'email')}
        />
      </Popover.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-100"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Popover.Panel className={classNames('absolute bg-white bottom-5', expanded ? 'left-44' : 'left-16')}>
          <ProfilePopoverContent
            name={_.get(data, 'name')}
            showAccountSettings={showAccountSettings}
            accountSettings={accountSettings}
          />
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}

ProfilePopover.propTypes = {};

export default memo(ProfilePopover);
