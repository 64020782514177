/**
 *
 * Button
 *
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import { FormattedMessage } from 'react-intl';

export function Button(props) {
  const { size, children, type, loading, LeadingIcon, TrailingIcon, disabled, selected, tooltip, ...rest } = props;

  const sizeMap = {
    xs: 'md:px-2 md:py-1.5 px-0.5 py-0.5 text-xs font-extralight',
    sm: 'md:px-2.5 md:py-1.5 px-1 py-1 text-sm leading-3 font-light',
    md: 'md:px-3 md:py-2 px-1.5 py-1.5 text-sm font-light ',
    lg: 'md:px-4 md:py-3 px-2 py-2 text-base font-medium',
    xl: 'md:px-6 md:py-2 px-2 py-2 text-lg font-semibold',
  };

  const iconSizeMap = {
    xs: { leading: '-ml-1.5 mr-0.5 h-3 w-3', trailing: 'ml-0.5 -mr-0.5 h-3 w-3', loading: 'h-3 w-3' },
    sm: { leading: '-ml-2 mr-0.5 h-3 w-4', trailing: 'ml-1 -mr-0.5 h-4 w-4', loading: 'h-4 w-4' },
    md: { leading: '-ml-1 mr-1 h-4 w-4 ', trailing: 'ml-1 -mr-1 h-4 w-4', loading: 'h-4 w-4' },
    lg: {
      leading: '-ml-2 mr-1 md:h-6 md:w-6 h-4 w-4',
      trailing: 'ml-2 -mr-1 h-6 w-6',
      loading: 'md:h-6 md:w-6 h-3 w-3',
    },
    xl: {
      leading: '-ml-2 mr-1 md:h-8 md:w-8 h-4 w-4',
      trailing: 'ml-2 -mr-1.5 h-8 w-8',
      loading: 'md:h-8 md:w-8 h-3 w-3',
    },
  };

  const colorMap = {
    primary: {
      base: 'focus:ring-neutral-800 bg-neutral-800 hover:bg-neutral-800-700 text-white',
      disabled: 'bg-[#8d8f91]',
    },
    secondary: { base: 'focus:ring-zinc-500 bg-zinc-500 hover:bg-zinc-700 text-white', disabled: 'bg-zinc-300' },
    warning: { base: 'focus:ring-amber-500 bg-amber-500 hover:bg-amber-700 text-white', disabled: 'bg-amber-300' },
    danger: { base: 'focus:ring-red-500 bg-red-500 hover:bg-red-700 text-white', disabled: 'bg-rose-300' },
    transparent: {
      base: classNames(
        'focus:ring-neutral-800 border border-neutral-800 text-neutral-800-700 bg-white shadow-sm',
        'hover:bg-zinc-100 rounded-md',
      ),
      disabled: ' border border-neutral-800-300 bg-zinc-100 text-neutral-800-700',
    },
    delete: { base: 'bg-rose-800 text-white border-rose-800', disabled: 'bg-amber-300' },
    success: {
      base: 'bg-blue-700 text-white border-blue-700',
      disabled: 'opacity-50 bg-blue-700 text-white border-blue-700',
    },
    secondaryTransparent: { base: 'border  border-blue-700 text-blue-700', disabled: 'bg-zinc-300' },
    filterButton: {
      base: 'border border-gray-200 bg-white rounded-md shadow-none',
      selected: 'border border-blue-600 text-blue-700 ',
    },
    dark: { base: ' bg-gray-900  text-white', disabled: 'bg-zinc-300' },
    reset: { base: 'border-gray-300 text-gray-900', disabled: 'bg-zinc-300' },
    discard: { base: 'border-gray-300 bg-gray-100 text-gray-900', disabled: 'bg-zinc-300' },
  };

  return (
    <>
      <button
        type="button"
        disabled={disabled}
        selected={selected}
        className={classNames(
          'inline-flex items-center border justify-center align-middle relative',
          get(sizeMap, size),
          disabled ? 'pointer-events-none' : '',
          'rounded-md shadow-sm focus:outline-none',
          get(colorMap, `${type}.${disabled ? 'disabled' : 'base'}`),
          get(colorMap, `${type}.${selected ? 'selected' : 'base'}`),
        )}
        data-for={get(tooltip, 'id')}
        data-tip={!!tooltip}
        {...rest}
      >
        {selected && <div className="bg-blue-700 rounded-2xl absolute top-[-4px] right-1.5 h-2 w-2" />}
        {loading && (
          <svg
            className={classNames(
              'animate-spin -ml-1 mr-3',
              type === 'transparent' ? 'text-black' : 'text-white',
              get(iconSizeMap, `${size}.loading`),
            )}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962
            7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
        )}
        {!loading && LeadingIcon && (
          <div className={classNames(children ? '' : 'ml-2')}>
            <LeadingIcon className={get(iconSizeMap, `${size}.leading`)} />
          </div>
        )}
        <div className="md:text-base text-sm font-light md:font-medium">{children}</div>
        {!loading && TrailingIcon && <TrailingIcon className={classNames(get(iconSizeMap, `${size}.trailing`))} />}
      </button>
      {tooltip?.id && (
        <ReactTooltip id={get(tooltip, 'id')} place="bottom" effct="solid">
          <FormattedMessage {...tooltip} />
        </ReactTooltip>
      )}
    </>
  );
}

Button.propTypes = {
  loading: PropTypes.bool,
  tooltip: PropTypes.shape({
    id: PropTypes.string,
    defaultMessage: PropTypes.string,
  }),
  TrailingIcon: PropTypes.object,
  LeadingIcon: PropTypes.object,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  type: PropTypes.oneOf([
    'primary',
    'secondary',
    'danger',
    'warning',
    'transparent',
    'delete',
    'success',
    'secondaryTransparent',
    'filterButton',
    'dark',
    'reset',
    'discard',
  ]),
  size: PropTypes.oneOf(['sm', 'xs', 'md', 'lg', 'xl']),
};

Button.defaultProps = {
  loading: false,
  disabled: false,
  type: 'primary',
  size: 'md',
};

export default memo(Button);
