/**
 *
 * Error
 *
 */

import React, { useEffect, useState, memo } from 'react';
import PropTypes from 'prop-types';

import { BanIcon, HomeIcon, ClipboardCopyIcon } from '@heroicons/react/solid';
import { FormattedMessage } from 'react-intl';
import * as Sentry from '@sentry/react';

import classNames from 'classnames';

import Img from 'images/500.svg';

import { Button, Notification } from 'components/base';

import messages from './messages';

export function Error(props) {
  const { error, id, trace } = props;
  const [clipError, setClipError] = useState(null);
  const [clipSuccess, setClipSuccess] = useState(null);

  useEffect(() => {
    if (error) {
      if (trace) {
        // eslint-disable-next-line no-console
        console.error(error.stack);
      } else {
        Sentry.captureException(error);
      }
    }
  }, []);
  const onClickCopy = () => {
    try {
      if (error) navigator?.clipboard?.writeText(error.stack);
      setClipSuccess(true);
    } catch (e) {
      Sentry.captureException(e);
      setClipError(true);
    }
  };
  return (
    <div className="h-full bg-slate-50 relative">
      {clipError && (
        <Notification header="Clipboard" type="danger" showIcon>
          <FormattedMessage {...messages.clipError} />
        </Notification>
      )}
      {clipSuccess && (
        <>
          <Notification header="Clipboard" type="info" onClose={() => setClipSuccess(false)} showIcon>
            <FormattedMessage {...messages.clipSuccess} />
          </Notification>
        </>
      )}
      <div className="h-full flex justify-center items-center ">
        <div className="flex shadow-lg items-center p-6 rounded-md bg-white w-2/3 ">
          <div
            className={classNames(
              'flex flex-grow-0 flex-col space-y-8 flex-shrink-0 w-full ',
              trace ? '' : 'md:w-1/2 ',
            )}
          >
            <h2 className="flex- md:text-2xl text-2xl font-semibold leading-8 align-middle items-center">
              <BanIcon className="w-7 h-7 text-red-600 inline md:hidden" />
              <FormattedMessage {...messages.header} />
            </h2>
            <div className="text-lg break-word overflow-scroll max-h-96">
              {error && trace ? (
                <pre className="break-all text-sm bg-gray-100 p-4">{error.stack}</pre>
              ) : (
                <>
                  {id && (
                    <div className="font-medium text-md font-mono bg-gray-100 p-2">
                      <FormattedMessage {...messages.trace} className="mr-2" />
                      <pre className="inline"> {id}</pre>
                    </div>
                  )}
                  <div className="italic">
                    <FormattedMessage {...messages.body} />
                  </div>
                </>
              )}
            </div>
            <div className="max-w-md space-x-4">
              {error && (
                <Button type="secondary" size="md" LeadingIcon={ClipboardCopyIcon} onClick={onClickCopy}>
                  <FormattedMessage {...messages.copy} />
                </Button>
              )}

              <Button type="primary" size="md" LeadingIcon={HomeIcon} onClick={() => window?.location.reload()}>
                <FormattedMessage {...messages.home} />
              </Button>
            </div>
          </div>
          <div
            className={classNames(
              'hidden p-8 flex-grow-0 flex flex-col space-y-2',
              trace ? '' : 'sm:block md:w-1/2 md:max-w-lg ',
            )}
          >
            <img src={Img} alt="Forbidden" className="w-full" />
            <p className="text-xs float-right font-extralight text-gray-300 italic">
              <FormattedMessage {...messages.dave} />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

Error.propTypes = {
  error: PropTypes.object,
  trace: PropTypes.bool,
  id: PropTypes.string,
};

Error.defaultProps = {
  trace: !['production', 'test'].includes(process.env.NODE_ENV),
};

export default memo(Error);
