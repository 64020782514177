/**
 *
 * Navbar
 *
 */

import React, { memo } from 'react';

import { compose } from 'redux';
import PropTypes from 'prop-types';

import getItems from './items';
import SideNavigation from '../SideNavBar';

export function Navbar(props) {
  const { hidden, isAuthenticated, roles } = props;
  const items = getItems({ roles });

  if (hidden) return <></>;
  return <>{isAuthenticated && <SideNavigation items={items} />}</>;
}

Navbar.propTypes = {
  hidden: PropTypes.bool,
};

Navbar.defaultProps = {
  hidden: false,
};

export default compose(memo)(Navbar);
