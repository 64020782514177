import { Player as LottiePlayer } from '@lottiefiles/react-lottie-player';
import Button from 'components/TableFilters/NewButton';
import reportIssueSuccess from 'images/gif/reportIssueSuccess.json';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

const SuccessView = props => {
  const { handleClose } = props;
  return (
    <div className="flex flex-1 flex-col justify-center items-center w-96">
      <div className="flex flex-1 justify-center items-center flex-col w-[304px] py-6">
        <div className="w-[178px] h-[178px]" data-chromatic="ignore">
          <LottiePlayer autoplay loop src={reportIssueSuccess}></LottiePlayer>
        </div>
        <p className="text-xl font-semibold text-gray-900 py-3">
          <FormattedMessage {...messages.success_title} />
        </p>
        <p className="text-base	text-gray-700">
          <FormattedMessage {...messages.success_summary} />
        </p>
        <div className="w-[90px] py-7">
          <Button type="tertiary" size="sm" isFullWidth onClick={handleClose}>
            <FormattedMessage {...messages.close} />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SuccessView;
