import classNames from 'classnames';
import React from 'react';

const Tooltip = ({ children, text, expanded }) => (
  <div className="relative flex items-center group">
    {children}
    {!expanded && (
      <div className="w-max absolute left-7 items-center hidden ml-6 group-hover:flex z-30">
        <span
          className={classNames(
            'rounded relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-black',
            'bg-opacity-70',
          )}
        >
          {text}
        </span>
      </div>
    )}
  </div>
);

export default Tooltip;
