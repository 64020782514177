import { defineMessages } from 'react-intl';

export const scope = 'app.components.base.SideNavBar.Admin';

export default defineMessages({
  users: {
    id: `${scope}.users`,
    defaultMessage: 'Users',
  },
  forms: {
    id: `${scope}.forms`,
    defaultMessage: 'Forms',
  },
  groups: {
    id: `${scope}.groups`,
    defaultMessage: 'Groups',
  },
  notification_tracker: {
    id: `${scope}.notification_tracker`,
    defaultMessage: 'Notification Tracker',
  },
  transactions: {
    id: `${scope}.transactions`,
    defaultMessage: 'Transactions',
  },
  configurations: {
    id: `${scope}.configurations`,
    defaultMessage: 'Configurations',
  },
  adminConsoleSummary: {
    id: `${scope}.adminConsoleSummary`,
    defaultMessage: 'Manage User Access and Roles',
  },
  formsSummary: {
    id: `${scope}.formsSummary`,
    defaultMessage: 'Customize and Control Forms ',
  },
  groupsSummary: {
    id: `${scope}.groupsSummary`,
    defaultMessage: 'Create and Manage Groups',
  },
  notification_trackerSummary: {
    id: `${scope}.notification_trackerSummary`,
    defaultMessage: 'Track Email Notifications',
  },
  transactionsSummary: {
    id: `${scope}.transactionsSummary`,
    defaultMessage: 'Monitor Credit Usage and Balances',
  },
  configurationsSummary: {
    id: `${scope}.configurationsSummary`,
    defaultMessage: 'Configure Organizational Preferences',
  },
});
