/*
 * NotificationsModal Messages
 *
 * This contains all the text for the NotificationsModal component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.base.SideNavBar.NotificationsPopover';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Notification',
  },
  mark_read: {
    id: `${scope}.mark_read`,
    defaultMessage: 'Mark all as Read',
  },
  no_messages: {
    id: `${scope}.no_messages`,
    defaultMessage: 'No messages available',
  },
});
