import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import classNames from 'classnames';

export function Cell({ getCellProps, column, render, backgroundClassNames }) {
  return (
    <td
      {...getCellProps({
        className: classNames(
          get(column, 'className') || 'table-cell',
          'lg:pt-4 lg:pb-4 p-2',
          column.sticky && `sticky ${backgroundClassNames} shadow-[inset_-2px_0px_0px_0px_#E4E7ED]`,
        ),
        style: { left: column.sticky ? column.totalLeft : '' },
      })}
    >
      {render('Cell')}
    </td>
  );
}
Cell.propTypes = {
  getCellProps: PropTypes.func,
  minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  column: PropTypes.object,
  render: PropTypes.func,
};

export default memo(Cell);
